@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
:root {
  height: 100%;
}

@layer base {
  :root {
    --background: 221, 80%, 4%, 1;
    --foreground: 0, 0%, 93%, 1;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 212, 46%, 7%, 1;
    --popover-foreground: 210 40% 98%;

    --primary: 54, 100%, 57%, 1;
    --primary-foreground: 0, 0%, 100%, 1;

    --secondary: 0, 0%, 100%, 1;
    --secondary-foreground: 0, 0%, 16%, 1;

    --muted: 0, 0%, 64%, 0.4;
    --muted-foreground: 0, 0%, 73%, 1;

    --accent: 0, 0%, 27%, 0.2;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 0, 0%, 40%, 0.2;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: hsl(54, 100%, 57%, 1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.gradient-text {
  letter-spacing: 0.01em;
}

@keyframes gradientText1 {
  0%,
  16.667%,
  to {
    opacity: 1;
  }

  33.333%,
  83.333% {
    opacity: 0;
  }
}

@keyframes gradientText2 {
  0%,
  16.667%,
  66.667%,
  to {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
}

@keyframes gradientText3 {
  0%,
  50%,
  to {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}

.gradient-text {
  @apply inline;
}

.gradient-text-1::after {
  content: 'Discover. ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to left, #007cf0, #00dfd8);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradientText1 8s ease infinite;
  display: flex;
  align-items: center;
}

.gradient-text-2::after {
  content: 'Integrate. ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to right, #7928ca, #ff0080);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradientText2 8s ease infinite;
  display: flex;
  align-items: center;
}

.gradient-text-3::after {
  background: linear-gradient(to right, #ff4d4d, #f9cb28);
  content: 'Scale. ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  margin-top: 0.2px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradientText3 8s ease infinite;
  display: flex;
  align-items: center;
}

.bracket {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(90deg, #ea4e38 4.67%, #ba2cb8 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


input[type="date"]::-webkit-calendar-picker-indicator {
   color: #007cf0;
   cursor: pointer;
  color-scheme: dark;
}


